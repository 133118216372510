import { useContext, useEffect, useLayoutEffect, useState, useRef, forwardRef, useCallback } from "react";
import UserContext from "../stores/UserContext";
import { Button, Text, View, StyleSheet, Dimensions } from "react-native";
import { TextInput } from "react-native";
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import { MaterialCommunityIcons } from '@expo/vector-icons';

let totalTimer = 1200
let mustTimer = 240

let ScreenHeight = Dimensions.get("window").height;
let ScreenWidth = Dimensions.get("window").width;
let topPixels = Math.floor(ScreenHeight / 100 * 20)
let cellPixels = Math.floor((ScreenHeight / 100 * 50) /15);

let iconSize = (ScreenWidth < 330 || ScreenHeight < 640) ? 28 : 32;


const EffortTask = ({navigation}) => {
    const { state, setGridAttempt, getEffort } = useContext(UserContext);
    const [ isReady, setIsReady ] = useState(false);
    const [ isCompleted, setIsCompleted ] = useState(false);
    const [ confirmed, setConfirmed ] = useState(false);
    const [ userPred, setUserPred ] = useState(-1);
    const [ gridsCompleted, setGridsCompleted ] = useState(0)
    const [ totalAttempts, setTotalAttempts ] = useState(0)
    const [ currentGridAttempts, setCurrentGridAttempts ] = useState(0);
    const [ rt, setRt ] = useState(1200)

    if (Object.is(NaN, userPred)) {
        setUserPred(-1);
    }
    const validateUserPredication = (value) => {
        try {
            if (value < 0 || value > 150) {
                console.log("Value is out of bounds");
                setUserPred(-1);
                return;
            }
            setUserPred(Math.floor(parseInt(value)));
            setConfirmed(false);
        } catch(ex) {
            console.error('Invalid Input ', value);
        }
    }

    const makeAttempt = () => {
        setCurrentGridAttempts(currentGridAttempts + 1);
        setGridAttempt(attemptNext, userPred);
        setTotalAttempts(totalAttempts + 1);
    }

    const attemptNext = () => {
        // console.log(state.answer)
        if (userPred === state.answer) {
            getEffort(resetScreen);
            setCurrentGridAttempts(0);
            setGridsCompleted(gridsCompleted + 1);
        } else {
            setConfirmed(true);
            if (currentGridAttempts >= 2) {
                getEffort(resetScreen);
                setCurrentGridAttempts(0);
            }
        }
    }

    const resetScreen = () => {
        setUserPred(-1);
        setConfirmed(false);
        setIsReady(true);
        setIsCompleted(false);
    }

    const endGame = () => {
        setIsReady(false);
        setIsCompleted(true);
    }

    useEffect(() => {
        if (state.grid !== undefined) {
            resetScreen();
        }
    }, [state.grid]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
              <></>
            ),
            headerShown: false
          });
    })

    return (
        <View style={styles.outer}>
            <View style={styles.mainContainer}>
                <View style={styles.topContainer}>
                    {
                        (isReady) ?
                        <View style={styles.textTimerContainer}>
                            <View style={styles.topTextContainer}>
                                {
                                    (rt < totalTimer - mustTimer) ? <Button style={{verticalAlign: "middle"}} onPress={endGame} title="Leave" /> :
                                        <Text style={{fontSize: 20}}>How many zeros are in the table.</Text>   
                                }
                                
                            </View>
                            <View style={{width: '20%'}}>
                                <CountdownCircleTimer
                                    isPlaying={isReady}
                                    duration={totalTimer}
                                    colors={["#004777", "#F7B801", "#A30000", "#A30000", "#2ebc6d"]}
                                    colorsTime={[totalTimer, totalTimer-120, totalTimer-180, totalTimer-210, totalTimer-mustTimer]}
                                    onComplete={() => {
                                        endGame();
                                        return { shouldRepeat: false, delay: 2 }
                                    }}
                                    onUpdate={(remainingTime) => setRt(remainingTime)}
                                    updateInterval={1}
                                    size={60}
                                    strokeWidth={3}
                                >
                                    {({ remainingTime, color }) => {
                                        return (
                                            <Text style={{ color, fontSize: 24 }}>{remainingTime}</Text>
                                        )}
                                    }
                                        
                                </CountdownCircleTimer>
                            </View>
                        </View>
                        : null
                    }
                    { (confirmed) ?
                            (userPred !== state.answer) ?
                                <Text>Try Again.</Text> : <Text>Correct!</Text>
                            :
                            null
                        }
                    {
                        (isReady) ?
                        <View style={styles.textTimerContainer}>
                        
                            <View>
                                <TextInput style={styles.inputBox} value={(userPred === -1 || Object.is(NaN, userPred)) ? 0 : userPred} returnKeyType='done' keyboardType='number-pad' step={1} min={0} max={state.grid.length*state.grid[0].length} onChangeText={validateUserPredication} />
                            </View>
                            { (userPred !== -1) ?
                                    <View  style={styles.buttonConfirm}>
                                        <Button onPress={() => makeAttempt()} title="Confirm"/>
                                    </View>
                                :
                                    <></> 
                            }
                        </View>
                        : null
                    }
                </View>
                {
                    (isReady) ?
                    <View style={styles.bottomContainer}>
                        <View style={styles.gridContainer}>
                            { state.grid.map((row, i) => {
                                return (
                                    <View key={`row_${i}`}>
                                        { row.map((cell, j) => <Text style={[styles.tableCell]}  key={`cell_${i},${j}`}>{(cell === 0) ? <MaterialCommunityIcons name="numeric-0" size={iconSize} color="black" /> : <MaterialCommunityIcons name="numeric-1" size={iconSize} color="black" />}</Text>) }
                                    </View>
                                )
                            }) }
                        </View>
                     </View> 
                    : null
                }
                {
                    (isCompleted) ?
                    <View>
                        <Text>Thank you for taking part in this task. {(gridsCompleted > 0) ? `You completed ${gridsCompleted} grids within ${Math.ceil((totalTimer - rt) / 60)} minutes, during this time you made ${totalAttempts} attempts.`: null}</Text>
                        {/* <Button onPress={() => navigation.navigate('Index')} title="Main Page"/> */}
                    </View>
                    : null
                }
            </View>
        </View>
    )
};

const styles = StyleSheet.create({ 
    outer: {
        margin: 0,
        padding: 0,
        display: 'flex',
        flex: 1,
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: 'antiquewhite'
    },
    bottomContainer: {
        width: '100%',
        alignItems: 'center',
    },
    topContainer: {
        paddingBottom: 25,
        alignItems: 'center',
        width: '100%',
        height: topPixels
    },
    textTimerContainer: {
        flexDirection:'row', paddingTop: 5
    },
    topTextContainer: {width: '80%', marginRight:25, marginTop: 10},
    gridContainer: {
        display:'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        margin: 'auto',
    },
    tableCell: {
        alignSelf: 'center',
    },
    inputBox: {
        marginTop: 10,
        height: 40,
        width: 150, 
        fontSize: 24,
        textAlign: 'center',
        alignSelf: "center",
        borderWidth: 1,
        borderRadius: 5,
        backgroundColor: 'white',
    },
    resultsContainer: {
        marginTop: '20%',
    }, 
    resultsContainerText: {
        fontSize: 'large',
    },
    correct: {
        color: 'green',
        fontSize: 'x-large',
    },
    wrong: {
        color: 'red',
        fontSize: 'x-large',
    },  
    buttonConfirm: {
        marginTop: 10,
        marginLeft: 5,
        height: 40,
        alignSelf: "center",
    },   
});

export default EffortTask;