import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { StyleSheet, Text, Button, Pressable, useWindowDimensions } from 'react-native';
import { ActivityIndicator, Card, Colors, Surface } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import { FontAwesome5 } from '@expo/vector-icons';

import UserContext from "../stores/UserContext";
import { useCallbackRef } from "use-callback-ref";

const Memory = ({navigation}) => {
    const { state, setMemory } = useContext(UserContext);
    const [render, setRender] = useState(0);
    const [seen, setSeen] = useState(false);
    const [exit, setExit] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const timer = useRef(null);

    // A. Khosla, A. S. Raju, A. Torralba, and A. Oliva, ‘Understanding and Predicting Image Memorability at a Large Scale’, in 2015 IEEE International Conference on Computer Vision (ICCV), Santiago, Chile: IEEE, Dec. 2015, pp. 2390–2398. doi: 10.1109/ICCV.2015.275.

    const timeShown = 600;
    const timeHidden = 800;

    const width = useWindowDimensions().width;
    const height = useWindowDimensions().height;

    const respStyle = { 
        borderColor: (seen) ? 'green' : 'white',
        borderWidth: 10,
        width: (width < height) ? (width / 10 * 9): 'auto',
        height: (width < height) ? 'auto': (height / 10 * 5),
    }

    const test = useCallbackRef(0, () => {
        if (state.tests[test.current - 1].distance === null) {
            console.log(state.tests[test.current - 1].type)
        }
        setMemory(state.tests[test.current - 1]._id, seen, state.tests[test.current - 1].distance, state.tests[test.current - 1].type);
        setRender(test.current);
        setSeen(false);
    });

    const final = useCallbackRef(false, () => {
        setMemory(state.tests[test.current]._id, seen, state.tests[test.current].distance, state.tests[test.current].type);
        navigation.navigate('Results', { path: 'M'});
    });

    const testSpacer = () => {
        clearTimeout(timer.current);
        timer.current = setTimeout(() => nextTest(), timeHidden);
        setRender(null);
    }

    const nextTest = () => {
        if (test.current + 1 < state.quantity) {
            test.current = test.current + 1;
            clearTimeout(timer.current);
            timer.current = setTimeout(() => testSpacer(), timeShown);
        } else {
            clearTimeout(timer.current);
            final.current = !final.current;
        }
    }

    useEffect(() => {
        if (state.tests !== undefined) {
            timer.current = setTimeout(() => testSpacer(), timeShown); 
            setIsReady(true)      
        }
    }, [state.tests]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
              <></>
            ),
            headerShown: false
          });
    })

    if (state.tests === undefined && !isReady) {
        return (
            <SafeAreaView style={styles.loadingContainer}>
                <ActivityIndicator animating={true} color={Colors.blue800} size={250} />
                <Text style={{fontSize: 72}} color={Colors.blue900}>Loading...</Text>
                <Button style={{marginTop:150}} title="CANCEL" onPress={() => navigation.navigate('Index')} />
            </SafeAreaView>
        );
    } 

    return (
        <SafeAreaView style={{flex: 1}}>
            <Card>
                {
                    (exit) ? <Button title="EXIT" onPress={() => {
                        clearInterval(timer.current);
                        navigation.pop();
                    } } /> : null
                }
                <Card.Content>
                    {/* <Text>{test.current}</Text> */}
                    {/* <Pressable onLongPress={() => setExit(!exit)} onPress={() => setSeen(!seen)} delayLongPress={1000}> */}
                    <Pressable onPress={() => setSeen(!seen)} delayLongPress={1000}>
                        {
                            (render !== null) ? <Card.Cover 
                                style={[styles.heroImage, respStyle]}
                                source={{uri: `data:image/jpeg;base64,${state.tests[render].base64}`}}
                            />
                            : <Card.Cover 
                                style={[styles.heroImage, respStyle]}
                                source={{uri: `data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=`}}
                            />
                        }
                        
                    </Pressable>
                    <Card.Actions style={styles.choiceContainer}>
                        <Pressable 
                            style={styles.choiceElement} 
                            onPress={() => {
                                setSeen(!seen)
                            }}
                        >
                            <Surface style={styles.surfaceStyle}>
                                <FontAwesome5 style={styles.imgElement} name={ (seen) ? `check-circle` : `times-circle`} size={100} color={ (seen) ? "green" : "black"} />
                                <Text style={styles.txtElement}>{(seen) ? `SEEN` : `Not seen`}</Text>
                            </Surface>
                        </Pressable>
                    </Card.Actions>
                </Card.Content>
            </Card>
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }, 
    heroImage: {
        aspectRatio: 1/1, 
        width: '90%', 
        height: 'auto',
        alignSelf: 'center',
        marginTop: 25,
    },
    choiceContainer: {
        flexDirection: 'row',
        borderTopWidth: 1,
        marginTop: 25
    },
    choiceElement: {
        flex: 1,
        marginTop: 15,
    },
    imgElement: {
        aspectRatio: 1/1,
        width: 100,
        height: "auto",
        alignSelf: 'center',
    },
    txtElement: {
        marginTop: 15,
        fontSize: 16,
        alignSelf: 'center'
    }, 
    surfaceStyle: {
        borderWidth: 1,
        borderColor: 'lightgrey',
        elevation: 9,
        padding: 8,
    }
});

export default Memory;