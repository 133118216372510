import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import { Button, StyleSheet } from "react-native";
import { ActivityIndicator, Card, Colors, Paragraph, Title } from "react-native-paper";
import { SafeAreaView } from "react-native-safe-area-context";
import UserContext from "../stores/UserContext";

const Start = ({navigation, route}) => {
    const { getImages, getMemory, getEffort } = useContext(UserContext);
    const { next, name } = route.params;
    const [ isNotReady, setIsNotReady ] = useState(true);

    const imagesReady = () =>  {
        setIsNotReady(false);
    }

    useEffect(() => {
        if (next === 'Memory') {
            getMemory(imagesReady);
        } else if (next === 'Effort') {
            getEffort(imagesReady);
        } else {
            getImages(imagesReady);
        }
    }, []);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
              <></>
            ),
            headerShown: false
          });
    })

    return (
        <SafeAreaView style={{flex: 1}}>
            <Card>
                <Card.Content>
                    <Title>Instructions for {name.toString() == "effort" ? "Number" : "Memory"} Game</Title>
                    {
                        (next === 'Memory') ?
                            <>
                            <Paragraph>
                                You will see a reel of 186 facial images, the objective is click / tap on the image to indicate if you have seen an image before within this session.
                            </Paragraph>
                            <Paragraph>
                                During the reel you will observe one photo for 0.6 of a second, this is followed by a blank fixation of 0.8 of a second, you can mark this image as seen anytime during this period of 1.4 seconds. After which the next photo will be presented.
                            </Paragraph>
                            <Paragraph>
                                At the conclusion, you will be presented with your results.
                            </Paragraph>
                            <Paragraph>
                                Once you have read these instruction, click the ready button below, the game will start instantly so be ready.
                            </Paragraph>        
                            </>
                        : (next === 'Effort') ?
                            <>
                            <Paragraph>
                                Enter the number of zeros into the box at the top of the screen. After you have entered the number, 
                                click the OK button. If you enter the correct result, a new table will be generated. If your input was 
                                wrong, you have two additional tries to enter the correct number of zeros. You therefore have a total 
                                of three tries to solve each table.
                            </Paragraph>
                            <Paragraph>
                                If you entered the correct number of zeroes <b>you can earn money</b>: you receive <b>£0.50 for each table you 
                                solve correctly</b>.
                            </Paragraph>        
                            <Paragraph>
                                You are asked to play the game for <b>4 minutes</b>. After 4 minutes you can carry on or you can decide to stop 
                                playing. If you decide to carry on, you have the chance of increasing your total reward. You have <b>16 more</b> minutes to complete the game. During these 16 minutes you can leave the game at any point by clicking on 
                                the 'leave' button. The remaining time is displayed in the upper right hand corner of the screen.
                            </Paragraph>
                            <Paragraph>
                                Counting tips: you can count the zeros any way you want. Speaking from experience, it is helpful to always 
                                count two zeros at once and multiply the resulting number by two at the end. 
                            </Paragraph> 
                            </>
                        : null
                    }
                </Card.Content>
                <Card.Actions>
                    {
                        (!isNotReady) 
                        ?
                            <Button title="READY" disabled={isNotReady} onPress={() => navigation.navigate(next)} />
                        :
                            <SafeAreaView style={styles.loadingContainer}>
                                <ActivityIndicator animating={true} color={Colors.blue800} size={50} />
                                <Paragraph color={Colors.blue900}>Loading...</Paragraph>
                                <Button style={{marginTop:150}} title="CANCEL" onPress={() => navigation.navigate('Index')} />
                            </SafeAreaView>
                    }
                </Card.Actions>
            </Card>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    }, 
})

export default Start;