import React, { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { StyleSheet, Text, View, Platform } from 'react-native';
import { Button, Modal, Paragraph, Portal } from 'react-native-paper';
import UserContext from '../stores/UserContext';

import buttons from '../styles/buttons'

const Index = ({ navigation }) => {
    const { state, logout, session, loginParams } = useContext(UserContext);
    const [visible, setVisible] = useState(false);

    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    useEffect(() => {
        try {
            if (Platform.OS === 'web') {
                const urlParams = new URLSearchParams(window.location.search);
                const id = urlParams.get('id');
                if (id !== null) {
                    loginParams(id);
                }
            }
        } catch(e) {
            console.log("Not Params")
        }
    }, []);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
              <></>
            ),
            headerShown: false
          });
    })

    const userMessage = (state.isLoggedIn) ? `User: ${state.id} ${state._id}` : 'Sign in';

    // const startSession = (name) => {
    //     navigation.navigate('Start', { next: 'Test', name });
    // }

    const startMemory = (name) => {
        navigation.navigate('Start', { next: 'Memory', name });
    }

    const startEffort = (name) => {
        navigation.navigate('Start', { next: 'Effort', name });
    }

    if (state.isLoggedIn === false) {
        return (
            <View style={styles.container}>
                <Paragraph>LOADING...</Paragraph>
                {/* <Button style={[buttons.button, {marginTop: 25}]} labelStyle={buttons.label} mode='contained' onPress={() => navigation.navigate('Signin')}>Sign In</Button>
                <Button style={buttons.button} labelStyle={buttons.label} mode='contained' onPress={() => navigation.navigate('Register')}>Register</Button> */}
            </View>
        );
    } else {
        if (Platform.OS === 'web') {
            const urlParams = new URLSearchParams(window.location.search);
            const task = urlParams.get('task')
            if (!state.sessionToken) {
                if (task === 'memory') {
                    session('memory', startMemory);
                } else if (task === 'effort') {
                    console.log("NOT HERE")
                    session('effort', startEffort);
                }
            }
        }
        return (
            <View style={styles.container}>
                <Paragraph>LOADED...</Paragraph>
                {/* <Paragraph>You are signed in</Paragraph>
                <Paragraph style={{fontSize: 24, padding: 5}}>{userMessage}</Paragraph>
                <Button 
                    style={[buttons.button, {marginTop: 25, backgroundColor: 'lightgrey'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={() => {
                        session('effort', startEffort);
                    }}
                >The number game</Button>

                <Button 
                    style={[buttons.button, {marginTop: 25, backgroundColor: 'lightgrey'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={() => {
                        session('memory', startMemory);
                    }}
                >How good is your memory</Button>

                <Button 
                    style={[buttons.button, {marginTop: 50, backgroundColor: 'red'}]} 
                    labelStyle={buttons.label} 
                    mode='contained' onPress={showModal}
                >SignOut</Button>
                
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal}>
                        <Button 
                            style={[buttons.button, {marginTop: 50, backgroundColor: 'red'}]} 
                            labelStyle={buttons.label} 
                            mode='contained' onPress={() => {
                                hideModal();
                                logout();
                            }}
                        >Are you sure?</Button>
                    </Modal>
                </Portal> */}
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }, UserText: {
        paddingVertical:25,
        fontSize: 24,
    }
});

export default Index;